<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-03-24 15:48:25
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-03-01 15:00:24
 -->
<template>
  <div class="mainbody" v-if="!showmainbody">
    <div class="share" @click="cancelShare" v-show="isShareCard">
      <img src="@/assets/abt/img/fxbg.png" />
    </div>
    <!-- 开屏页 -->
    <md-landscape v-model="kpisshow" full-screen class="kp_box">
      <div class="kpbox" :style="{ backgroundColor: getStorage('theme') }">
        <img src="@/assets/jhs/img/kpy.png" alt="" class="head" />
        <span class="tbname"
          >敬呈 " {{ khinfo.name == "" ? user.nickname : khinfo.name }} " 亲启
        </span>
      </div>
    </md-landscape>
    <div
      class="plan"
      id="plan-pdf"
      :style="{ backgroundColor: getStorage('theme') }"
    >
      <div class="plan-top">
        <div class="banner">
          <img :src="form.Price" alt />
        </div>
      </div>
      <div class="plan-cen">
        <div class="basic">
          <div class="sex_age">{{ khinfo.sex }} {{ khinfo.age }}岁</div>
          <div class="basic-top clearfix">
            <ul>
              <li>
                <p>首年总保费</p>
                <span :style="{ color: getStorage('theme') }"
                  >{{ form.khinfo.allmount }}元</span
                >
              </li>
            </ul>
          </div>
          <div class="basic-bom">
            <div class="module-interv">
              <div class="table_width-title">
                <table>
                  <tr class="tr_background">
                    <th
                      class="th_public costother"
                      :style="{
                        backgroundColor: getStorage('theme'),
                        border: '1px solid ' + getStorage('theme'),
                      }"
                    >
                      产品名称
                    </th>
                    <th
                      class="th_public costother"
                      :style="{
                        backgroundColor: getStorage('theme'),
                        border: '1px solid ' + getStorage('theme'),
                      }"
                    >
                      保险金额
                    </th>
                    <th
                      class="th_public costother"
                      :style="{
                        backgroundColor: getStorage('theme'),
                        border: '1px solid ' + getStorage('theme'),
                      }"
                    >
                      缴费年限
                    </th>
                    <th
                      class="th_public costother"
                      :style="{
                        backgroundColor: getStorage('theme'),
                        border: '1px solid ' + getStorage('theme'),
                      }"
                    >
                      年缴保费
                    </th>
                  </tr>
                  <tr v-for="(item, index) of form.ZxInfo" :key="index">
                    <td class="tdbg">{{ item.prodname }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.yearnum }}</td>
                    <td>{{ item.yearmoney }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!convertshow">
          <div
            class="safeguard"
            v-for="(item, index) of form.zrList"
            :key="index"
          >
            <div class="safeguard-top">
              <div class="safeguard-topce">
                <p>{{ item.name }}</p>
                <span
                  class="types"
                  :style="{
                    color: getStorage('theme'),
                    border: '1px solid ' + getStorage('theme'),
                  }"
                  @click="types(item)"
                  v-if="item.ishasbz"
                  >病种</span
                >
              </div>
            </div>
            <div class="safeguard-bom">
              <div class="safeguard-bomp" v-for="(ic, i) of item.data" :key="i">
                <span
                  :style="{ backgroundColor: getStorage('theme') }"
                  class="roundpdf"
                ></span>
                <div class="pad_lef">
                  <p class="marker">
                    {{ ic.liabdesc
                    }}<span
                      v-if="ic.liabexp != 0"
                      class="spanblue"
                      :style="{ color: getStorage('theme') }"
                      >{{ ic.liabexp
                      }}{{ ic.liabatype == "2" ? "" : "元起" }}</span
                    >
                  </p>
                  <p class="cccp">{{ ic.prodname }}</p>
                  <!-- <p class="xzinfo" @click="all_show(ic, i, index)">
                    {{
                      ic.liabexplain.length > 50
                        ? ic.liabexplain.substr(0, 50) + "..."
                        : ic.liabexplain
                    }}
                  </p> -->
                  <p class="t_xzinfo">
                    {{ ic.liabexplain }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="convertshow" class="red">
          <div
            class="safeguard"
            v-for="(item, index) of form.cpList"
            :key="index"
          >
            <div class="safeguard-top">
              <p>{{ item.name }}</p>
            </div>
            <div class="safeguard-bom">
              <div class="safeguard-bomp" v-for="(ic, i) of item.data" :key="i">
                <span
                  :style="{ backgroundColor: getStorage('theme') }"
                  class="roundpdf"
                ></span>
                <div class="pad_lef">
                  <p class="marker">
                    {{ ic.liabdesc
                    }}<span
                      v-if="ic.liabexp != 0"
                      class="spanblue"
                      :style="{ color: getStorage('theme') }"
                      >{{ ic.liabexp
                      }}{{ ic.liabatype == "2" ? "" : "元起" }}</span
                    >
                  </p>
                  <p class="cccp">{{ ic.enumname }}</p>
                  <p>
                    {{ ic.liabexplain }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clause clause_clause">
          <div class="clausetop" @click="terms">
            <p>
              条款详情<img
                src="@/assets/jhs/img/next.png"
                alt=""
                v-if="!showterms"
              /><img src="@/assets/jhs/img/down.png" v-if="showterms" />
            </p>
          </div>
          <div class="safelist-bom" v-if="showterms">
            <div
              class="safeguard-bomp"
              v-for="(term, i) of form.tkList"
              :key="i"
            >
              <div class="markerr" v-if="term.tkname">
                <span
                  :style="{ backgroundColor: getStorage('theme') }"
                  class="roundpdf"
                ></span>
                <p>{{ term.tkname }}</p>
              </div>
              <span
                v-if="term.tkname"
                :style="{ border: '1px solid ' + getStorage('theme') }"
                @click="markerpdf(term)"
                class="original_text"
                >预览</span
              >
            </div>
          </div>
        </div>
        <div class="safeguard">
          <div class="safeguard-top">
            <p class="fegx">风险提示</p>
          </div>
          <div class="safeguard-bom">
            <div class="safeguard-bomp">
              <p class="dot">
                风险提醒：投保人在投保犹豫期后解除合同会遭受一定损失，具体保单利益以保险合同为准
              </p>
            </div>
            <div class="safeguard-bomp">
              <p class="dot">
                温馨提示：以上演示说明为本平台对上述产品的理解便于保险从业人员学习，交流，演示数据仅供参考，以实例为准。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="plan-bom">
        <div class="plan-bomcen">
          <div class="plan-bomcen-cen">
            <div class="plan-bomcen-img">
              <img :src="form.ywyinfo.headimg" alt="" />
            </div>
            <div class="plan-bomcen-txt">
              <p>{{ form.ywyinfo.cardempname }}</p>
            </div>
            <div class="plan-bomcen-btn">
              <p>{{ form.ywyinfo.cardmobile }}</p>
            </div>
            <div
              v-if="showdatapdf"
              class="forward"
              :style="{ backgroundColor: getStorage('theme') }"
            >
              <span @click="great_pdf">导出PDF</span>
            </div>
            <div
              v-if="showdatapdf"
              class="forward"
              :style="{ backgroundColor: getStorage('theme') }"
            >
              <span @click="shareCard">转发计划书</span>
            </div>
          </div>
        </div>
      </div>
      <!-- :style="{'backgroundColor':theme}" -->
      <div class="broadside">
        <div
          @click="convert(1)"
          :class="convertshow == false ? 'Currentpage' : 'Nopage'"
        >
          <p>按险种展示</p>
        </div>
        <div
          @click="convert(2)"
          :class="convertshow == true ? 'Currentpage' : 'Nopage'"
        >
          <p>按责任展示</p>
        </div>
      </div>
    </div>
    <md-popup
      v-model="isPopupShow"
      position="right"
      ok-text="ok"
      cancel-text="cancel"
    >
      <div class="md-example-popup md-example-popup-right">
        <div class="dangerlist">
          <div>
            <div class="dangerlistce" v-if="clausedata">
              <div class="dangerlistcetop">
                <div class="dangerlistcetopl">
                  <span class="dangerlistcetoplspan"></span>
                  <p>病种详情</p>
                </div>
                <div class="dangerlistcetopr" @click="mistake">
                  <img src="@/assets/jhs/img/cuo.png" alt="" />
                </div>
              </div>
              <div
                v-for="(item, ina) in pickerData"
                :key="ina"
                @click="clause(ina)"
                class="dangerlistcebom"
              >
                <p :class="item.clickren ? 'dangerlistcebomb' : ''">
                  {{ item.disname }}
                </p>
                <img src="@/assets/jhs/img/next.png" />
              </div>
            </div>
            <div class="bobrick" v-if="!clausedata" v-model="clausedatada">
              <div class="bobrickl" @click="getback">
                <p><img src="@/assets/jhs/img/zuo.png" />返回</p>
              </div>
              <div class="bobrickc">
                <p><span>【</span>{{ clausedatada.disname }}<span>】</span></p>
              </div>
              <div class="bobrickr" @click="mistake">
                <img src="@/assets/jhs/img/cuo.png" alt="" />
              </div>
            </div>
          </div>
          <div class="bobrickbom" v-if="!clausedata" v-model="clausedatada">
            <md-scroll-view :scrolling-x="false">
              <div
                v-for="(
                  ic, i
                ) in clausedatada.abtAbdModelClassLiabDisnolistList"
                :key="i"
                class="dangerlistcebombi"
              >
                <p>
                  <span>{{ i + 1 }}.</span>{{ ic.disname }}
                </p>
              </div>
            </md-scroll-view>
          </div>
        </div>
      </div>
    </md-popup>
    <md-popup v-model="revealshow" :mask-closable="closable">
      <div class="icdoling">
        <md-icon
          name="spinner"
          color="blue"
          size="lg"
          style="-webkit-filter: invert(1)"
        ></md-icon>
      </div>
    </md-popup>
  </div>
  <div v-else class="showmainbodyc">
    <span @click="showmainbody = false" class="close"
      ><img src="@/assets/jhs/img/cancel.png"
    /></span>
    <iframe class="prism-player" :src="file" width="100%"></iframe>
    <!-- <pdf v-for="i in numPages"
         :key="i"
         :page="i"
         :src="pdfUrl"
         style="width: 100%; height: auto;"
         @num-pages="pageCount=$event"></pdf> -->
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { Picker, Toast } from "mand-mobile";
import {
  setStorage,
  getStorage,
  stopScroll,
  canScroll,
  isAndroid,
} from "@/lib/util";
import { wechatshare } from "@/lib/wechat_share";
import {
  showresport,
  returnreport,
} from "@/api/jhs/productlist/index";
import { DoRecord } from "@/api/abt/customerOperation/common/index";
import initWebSocket from "@/mixins/websock";
import config from "@/config";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";

const { redirect_uri, cvu } = config;
export default {
  components: {
    pdf,
    [Picker.name]: Picker,
  },
  mixins: [initWebSocket],
  data() {
    return {
      file: "",
      closable: false,
      revealshow: false,
      isShareCard: false,
      jhsnono: "",
      imgurl: "",
      pdfUrl: "",
      pageCount: 0,
      numPages: 0,
      picurl: "",
      clausedata: true,
      clausedatada: {},
      /* 行为追踪 */
      isPickerShow1: false,
      user: {},
      empno: "",
      rytype: "",
      suserid: "",
      fromwhere: "",
      convertshow: false,
      isPopupShow: false,
      showsyr: false,
      showterms: true,
      showmainbody: false,
      form: {
        ZxInfo: [],
        khinfo: {
          allmount: "",
        },
        ywyinfo: {
          headimg: "",
          cardempname: "",
          cardmobile: "",
        },
      },
      pickerDefaultIndex: [],
      dataa: {},
      pickerData: [],
      kpisshow: true,
      khinfo: {},
      tbage: {},
      jkbz_is: "1",
      markerpdfurl: "",
      greate_pdf_url: "",
      showdatapdf: false,
      pdf_height: "", //单页的pdf的高度
      pdf_width: "", //单页的pdf的宽度
    };
  },
  beforeCreate() {
    if (!this.$route.query.jhsshare && getStorage("u_s", {}).rytype == "Y") {
      this.$router.replace({ path: "/wrong" });
      return;
    }
  },
  created() {
    if (!this.$route.query.jhsshare || this.$route.query.type == "pc") {
      this.kpisshow = false;
    }
    this.theme = getStorage("theme", "");
    this.imgurl = cvu;
    this.fromwhere = this.$route.query.fromwhere
      ? this.$route.query.fromwhere
      : "";
    this.user = getStorage("u_s", {});
    this.empno = this.$route.query.empno
      ? this.$route.query.empno
      : this.user.empno;
    if (this.empno == this.user.empno) {
      this.showdatapdf = true;
    }
    this.qempno = this.$route.query.qempno
      ? this.$route.query.qempno
      : this.user.qempno;
    this.rytype = this.$route.query.rytype
      ? this.$route.query.rytype
      : this.user.rytype;
    this.suserid = this.$route.query.suserid
      ? this.$route.query.suserid
      : this.user.userid;
    this.mold = this.$route.query.mold;
    this.jhsnono = getStorage("jhsno", "");
    if (!this.mold) {
      if (!this.$route.query.type) {
        showresport({
          jhsno: this.$route.query.jhsno,
          comid: this.user.comid,
          empno: this.user.empno,
        }).then((res) => {
          document.title = res.data.data.ZxInfo[0].prodname
            ? res.data.data.ZxInfo[0].prodname
            : "产品计划书";
          this.form = res.data.data;
          this.khinfo = res.data.data.khinfo;
          this.tbage = res.data.data.reporlinfo;
          let fxstr = {
            suserid: this.suserid,
            empno: this.empno,
            otype: "2",
            btagcode: "8",
            rytype: this.rytype,
            scmap: {
              prodname: this.form.ZxInfo[0].prodname,
              jhsno: this.$route.query.jhsno,
            },
          };
          let shareurl = `${redirect_uri}/responsibility?jhsno=${this.$route.query.jhsno}&empno=${this.empno}&recordtype=8&suserid=${this.user.userid}&rytype=${this.rytype}&jhsshare=true`;
          if (this.fromwhere !== "app") {
            if (
              !this.$route.query.jhsshare &&
              getStorage("u_s", {}).rytype == "Y"
            ) {
            } else {
              this.behaviorRecord();
            }
            wechatshare(
              this.form.ZxInfo[0].prodname + "计划书",
              "您的好友" +
                this.user.nickname +
                "邀请您查看" +
                this.form.ZxInfo[0].prodname +
                "计划书",
              this.user.headimg,
              encodeURI(shareurl),
              redirect_uri,
              fxstr
            );
          }
          setTimeout(() => {
            this.kpisshow = false;
          }, 1500);
        });
      } else {
        showresport({
          jhsno: this.$route.query.jhsno,
          comid: this.$route.query.comid,
        }).then((res) => {
          this.form = res.data.data;
          this.khinfo = res.data.data.khinfo;
          this.tbage = res.data.data.reporlinfo;
        });
      }
    } else {
      this.form = this.jhsnono;
    }
  },
  watch: {
    isPopupShow(val) {
      if (val) {
        stopScroll();
      } else {
        canScroll();
      }
    },
    "$route.name"(nVal) {
      const judgedr = navigator.userAgent.indexOf("dongrui");
      if (isAndroid() && judgedr != -1) {
        window.JYTitleInterface.openPage();
      }
    },
  },

  methods: {
    getdata() {
      showresport({
        jhsno: this.$route.query.jhsno,
        comid: this.user.comid,
        empno: this.user.empno,
      }).then((res) => {
        this.form = res.data.data;
      });
    },
    change_icon_down(item, index) {
      this.jkbz_is = "index";
      this.getdata();
    },
    change_icon_right(item, index) {
      this.jkbz_is = index;
      this.getdata();
    },
    shareCard() {
      this.isShareCard = true;
    },
    cancelShare() {
      this.isShareCard = false;
    },
    convert(val) {
      if (val == 1) {
        this.convertshow = true;
      } else if (val == 2) {
        this.convertshow = false;
      }
      // this.convertshow = !this.convertshow;
    },
    terms() {
      // 条款详情
      this.showterms = !this.showterms;
    },
    types(val) {
      // 险种
      let list = [];
      for (let item in val.data) {
        let obj = {
          liabcode: "",
          prodcode: "",
        };
        obj.liabcode = val.data[item].liabcode;
        obj.prodcode = val.data[item].prodcode;
        list.push(obj);
      }
      let data = {
        list: list,
        comid: this.user.comid,
      };
      returnreport(data).then((res) => {
        if (Object.keys(res.data.data).length !== 0) {
          this.pickerData = res.data.data;
          for (let item in this.pickerData) {
            this.pickerData[item].clickren = false;
          }
          this.isPopupShow = true;
          this.clausedata = true;
        } else {
          Toast.failed("暂无病种信息!");
        }
      });
    },
    danger(val) {
      // 责任数据
      for (let item of val) {
        item.value = item.liabcode;
        item.label = item.liabdesc;
        item.children = item.abtAbdModelClassLiabDisnolistList;
        for (let ic of item.children) {
          ic.value = ic.discode;
          ic.label = ic.disname;
          ic.children = ic.abtAbdModelClassLiabUndislistList;
          for (let ie of ic.children) {
            ie.value = ie.undiscode;
            ie.label = ie.undisname;
          }
        }
      }
      this.ce = [val];
      this.isPickerShow1 = true;
    },
    clause(ina) {
      // 险种显示
      this.clausedatada = this.pickerData[ina];
      this.clausedata = false;
      this.$forceUpdate();
    },
    async loadPdfHandler() {
      this.pdfUrl = pdf.createLoadingTask(this.markerpdfurl);
      this.pdfUrl.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
    markerpdf(val) {
      // 下载pdf
      this.markerpdfurl = this.imgurl + val.tkurl;
      this.file = `${
        window.location.origin
      }/pdf/web/viewer.html?file=${encodeURIComponent(
        this.markerpdfurl
      )}#page=1`;
      this.showmainbody = true;
    },
    behaviorRecord() {
      let data = {
        suserid: this.suserid,
        empno: this.rytype === "N" ? this.qempno : this.empno,
        otype: "1",
        btagcode: "8",
        rytype: this.rytype,
        scmap: {
          prodname: this.form.ZxInfo[0].prodname,
          jhsno: this.$route.query.jhsno,
        },
      };
      DoRecord(data).then((res) => {
        let id = res.data.data.id;
        let comid = this.user.comid;
        this.initWebSocket(id, comid); // 开启websocket连接
      });
    },
    mistake() {
      this.isPopupShow = false;
    },
    getback() {
      this.clausedata = true;
    },
    // 生成pdf的事件
    great_pdf() {
      this.revealshow = true;
      window.scrollTo(0, 0);
      this.showdatapdf = false;
       let pageHeight = (document.body.clientWidth  / 580) * 1000;
        this.pdf_height = pageHeight + "px";
        setStorage("pdf_pageHeight", this.pdf_height);
        this.$router.push({
          path: "/pdf_template",
          query: {
            jhsno: this.$route.query.jhsno,
            reporturl: `${window.location.href}&empno=${this.empno}&rytype=${this.rytype}&jhsshare=true`,
          },
        });
    } 
  },
};
</script>
<style lang="stylus" scoped>
.md-example-popup {
  position: relative;
  font-size: 28px;
  font-family: DINPro;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  background-color: #FFF;
}

.md-example-popup-right {
  height: 100%;
  width: 80vw;
  display: flex;
  align-items: center;
}

.mainbody {
  overflow-x: hidden;
  background: color-primary;
  width: 100%;
}

.plan {
  height: 100%;
  background: color-primary;
}

.plan-top {
  height: 20vh;
}

.banner {
  text-align: center;
}

.banner img {
  height: 20vh;
}

.plan-cen {
  height: 100%;
  width: 95vw;
  margin: 0 auto;
  position: relative;
  top: -30px;
}

.basic, .safeguard, .clause {
  background: #fff;
  border-radius: 5px;
  padding: 20px 0;
  margin-bottom: 30px;
}

.clausetop {
  position: relative;
}

.clausetop p {
  height: 44px;
  line-height: 44px;
  text-align: center;
}

.clausetop img {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 50%;
  margin-top: -22px;
}

.clausetop .marker {
  margin-left: 20px;
}

.roundpdf {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  background-color: #FD5A38;
  display: inline-block;
}

.basic-top {
  height: 40%;
}

.basic-top ul {
  height: 100%;
  list-style: none;
}

.basic-top ul i {
  float: left;
  width: 2px;
  height: 60px;
  margin-top: 30px;
  background: #e0e0e0;
}

.basic-top ul li {
  float: left;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.basic-top ul li span {
  color: color-primary;
  font-weight: 600;
}

.basic-top ul li p {
  color: #393939;
  font-size: 34px;
}

.share {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  z-index: 999;
  position: fixed;
  top: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
  }
}

.basic-bom {
  margin-top: 50px;
}

.module-interv table {
  border: 0;
  cellspacing: 0;
  cellpadding: 0;
  margin: 0 auto;
}

.module-interv table td {
  font-size: 30px;
  border: 1px solid #efefef;
  height: 80px;
  text-align: center;
}

.original_text {
  text-align: center;
  border: 1px solid #FD5A38;
  border-radius: 0.5rem;
  font-size: 0.28rem;
  display: inline-block;
  height: 0.6rem;
  width: 1rem;
  line-height: 0.6rem;
}

.module-interv table .tdbg {
  background: #eaf1f9;
}

.module-interv table tr, .module-interv table th {
  border: 1px solid color-primary;
}

.tr_background {
  background-color: color-primary;
}

.th_public {
  height: 80px;
  text-align: center;
  font-size: 30px;
  color: #ffffff;
}

.pad_lef {
  padding-left: 0.2rem;
  flex: 1;
}

.costother {
  min-width: 190px;
}

.clearfix:after, .clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

// .clearfix {
// @css {
// *
// }
// zoom: 1;
// }
.safeguard-top {
  height: 100px;
  line-height: 100px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.safeguard-topce {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.types {
  width: 1.7rem;
  position: absolute;
  font-size: 0.45rem;
  right: 0.1rem;
  top: 0.05rem;
  line-height: 0.7rem;
  border: 1px solid color-primary;
  color: color-primary;
  font-weight: bold;
}

.safeguard-top .fegx {
  color: #ccc;
}

.safeguard-bom {
  width: 90%;
  margin: 0 auto;

  .icon_zk {
    width: 10%;
    text-align: center;
    margin: 0 auto;
    margin-top: 0.2rem;
  }

  p {
    font-size: 0.3rem;
    color: #9D9D9D;
  }
}

.safelist-bom p {
  padding-left: 0.2rem;
  width: 7rem;
}

.safeguard-bom p {
  margin: 15px 0;
  word-wrap: break-word;
}

.safeguard-bomp {
  display: flex;
  align-items: baseline;
  // -webkit-box-align: center;
  // margin-left: -0.2rem;
}

.clause_clause .safeguard-bomp {
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.3rem;
}

.markerr {
  display: flex;
  align-items: center;
  margin-left: 0.2rem;
}

.forward {
  width: 3rem;
  height: 0.8rem;
  margin-top: 0.1rem;
  border-radius: 0.1rem;
  text-align: center;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.forward>span {
  line-height: 0.8rem;
  font-size: 0.35rem;
  color: #fff;
}

.safeguard-bomp .markerpdf {
  width: 50px;
  height: 50px;
  line-height: 50px;
  float: right;
  color: color-primary;
}

.dot::before {
  content: '';
  width: 15px;
  height: 15px;
  background: #cccccc;
  border-radius: 50%;
  position: absolute;
  margin-top: 10px;
  margin-left: -25px;
}

.safeguard-bom .spanblue {
  padding-left: 10px;
  color: color-primary;
  font-weight: 700;
  font-size: 40px;
}

.safeguard-bom .cccp {
  margin: 0;
  font-size: 30px;
  color: #FED7A5;
}

.dot {
  font-size: 28px;
  color: #cccccc;
}

.plan-bom {
  height: 120px;
  background: #fff;
}

.plan-bomcen {
  width: 95vw;
  height: 120px;
  margin: 0 auto;
  position: relative;
}

.plan-bomcen-cen {
  width: 100%;
  height: 100px;
  display: flex;
  position: absolute;
  top: 50%;
  margin-top: -50px;
}

.plan-bomcen-img {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center; /* 实现垂直居中 */
}

.plan-bomcen-txt {
  height: 100%;
  display: flex;
  align-items: center; /* 实现垂直居中 */
  max-width: 1.8rem;
}

.plan-bomcen-txt p {
  padding-left: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.plan-bomcen-btn {
  height: 100%;
  padding-top: 5px;
  width: 50%;
  display: flex;
  align-items: center; /* 实现垂直居中 */
  margin-left: 0.15rem;
}

.plan-bomcen-btn p {
  text-align: center;
}

.plan-bomcen-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.broadside {
  cursor: pointer;
  position: fixed;
  top: 50%;
  width: 60px;
  // background: color-primary;
  text-align: center;
  border-radius: 15px 0px 0px 15px;
  right: 0;
}

.broadside p {
  color: #fff;
  padding: 10px 10px;
  font-size: 25px;
  word-wrap: break-word;
}

.Currentpage {
  border-radius: 15px 0px 0px 15px;
  background-color: #EBCECC;
  color: #E0B7B7;
}

.Nopage {
  border-radius: 15px 0px 0px 15px;
  background-color: #675C5B;
  color: white;
}

.dangerlist {
  width: 90%;
  height: 100%;
  margin: 0 auto;
}

.md-example-popup-right .md-scroll-view {
  width: 100%;
  height: 100%;
}

.dangerlistce {
  margin: 20px 0;
  height: 100px;
  background: #fff;
  border-radius: 10px;
}

.dangerlistce p {
  height: 100%;
  line-height: 100px;
}

.dangerlistcebom {
  position: relative;
  margin: 30px 0;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.dangerlistcebombi {
  text-align: left;
}

.dangerlistcebombi p {
  line-height: 80px;
}

.dangerlistcebombi span {
  padding: 0 10px;
}

.dangerlistcebomb {
  border-bottom: 1px solid #e0e0e0;
}

.close img {
  height: 0.9rem;
  width: 0.9rem;
  position: absolute;
  right: 0;
  top: 0;
}

.dangerlist>* {
  padding: 0;
}

.dangerlistcetop {
  height: 100px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
}

.dangerlistcetopl {
  width: 90%;
  text-align: left;
}

.dangerlistcetopl {
  display: flex;
}

.dangerlistcetoplspan {
  display: inline-block;
  height: 60%;
  width: 10px;
  margin: 20px 10px;
  background: #8699ff;
}

.dangerlistcetopr {
  width: 10%;
  position: relative;
}

.dangerlistcetopr img, .dangerlistcebom img, .bobrickr img, .bobrickl img {
  text-align: center;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 30px;
}

.dangerlistcebom p {
  text-align: left;
}

.bobrick {
  display: flex;
  height: 100px;
  border-bottom: 1px solid #e0e0e0;
}

.bobrickl {
  width: 20%;
  position: relative;
}

.bobrickl img {
  left: -10px;
  margin: 0px 10px;
}

.bobrickl p {
  color: #585858;
}

.bobrickc {
  width: 70%;
}

.bobrickr {
  position: relative;
  width: 10%;
}

.bobrickl p, .bobrickc p {
  line-height: 100px;
}

.bobrickc p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bobrickbom {
  height: 90vh;
}

.sex_age {
  background-color: #fff;
  margin-top: -0.7rem;
  padding-bottom: 0;
  text-align: center;
  width: 3rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 0.5rem;
  /* border: 1px solid #DEDBDF; */
  margin: -0.7rem auto 0 auto;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
}

.showmainbodyc {
  height: 100vh;
  background: #fff;
}

.iconc {
  border-top: 1px solid #fff;
  color: #fff;
  padding: 15px 0 0;
  text-align: center;
}

.iconc::after {
  content: '分享';
  color: white;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  width: 60px;
  font-size: 24px;
  background-color: color-primary;
}

.kpbox {
  height: 100vh;
  width: 100%;
}

.kp_box /deep/ .icon-font:before {
  content: '';
}

.head {
  width: 90%;
  margin-left: 5%;
  position: absolute;
  animation: myfirst 0.4s;
  -webkit-animation: myfirst 0.2s;
  animation-fill-mode: forwards; /* 当动画完成时，动画会停留在最后一帧。 */
  overflow: hidden;
}

@keyframes myfirst {
  0% {
    bottom: 20%;
    opacity: 0.3;
  }

  50% {
    bottom: 50%;
    opacity: 0.5;
  }

  100% {
    bottom: 60%;
    opacity: 1;
  }
}

.tbname {
  color: #ffffff;
  font-size: 0.4rem;
  position: absolute;
  bottom: 20%;
  animation: myname 0.4s;
  -webkit-animation: myname 0.2s;
  animation-fill-mode: forwards; /* 当动画完成时，动画会停留在最后一帧。 */
  overflow: hidden;
}

@keyframes myname {
  0% {
    right: 0;
    opacity: 0.3;
  }

  50% {
    right: 5%;
    opacity: 0.5;
  }

  100% {
    right: 10%;
    opacity: 1;
  }
}

.showmainbodyc iframe {
  height: 100%;
}

.zkmx {
  width: 25%;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.15rem;
}

/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}
</style>
